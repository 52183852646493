<template>
  <div>
    <Header/>
    <section id="cart">
      <div class="inner">
        <div class="breadcrumbs">
          購物車 <span
          :class="{'txt-lightgrey': $route.name === 'CartInfo'}">
          > 填寫資料與付款</span> <span class="txt-lightgrey">> 訂購完成
          </span>
        </div>
        <router-view></router-view>
      </div>
      <CartButtomBar
        @onSubmit="onSubmit"
        :ifFix="ifCartButtomBarFix"
        :prev="backRouterPath"
        :prevBtnText="backBtnText"
        :next="nextRouterPath"
        :nextBtnText="nextBtnText"/>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import moment from 'moment';
import Header from '@/components/Header.vue';
import CartButtomBar from '@/components/cart/CartButtomBar.vue';
import requestApi from '@/lib/http/index';

// import axios from 'axios';
import { required, checkMail, checkCompanyNo } from '@/consts/validator';
import '@/assets/scss/cart.scss';

export default {
  name: 'Cart',
  props: ['category'],
  components: {
    Header,
    CartButtomBar,
  },
  data() {
    return {
      // fix 總計bar用的
      footerOffsetTop: 0,
      screenHeight: 0,
      scrollDistence: window.scrollY,
      ifCartButtomBarFix: true,
      backRouterPath: '/Product/1',
      nextRouterPath: '/Cart/Detail',
      backBtnText: '繼續選購',
      nextBtnText: '前往結帳',
      formDataError: {
        county: '請填寫縣市',
        district: '請填寫鄉鎮區',
        address: '請填寫地址',
        zipcode: '無法取得郵遞區號(請填寫單雙號欄位)',
        take_way_id: '發生例外錯誤:無法取得取件方式資訊',
        name: '請填寫收件人姓名',
        sex: '請填寫收件人性別',
        email: '請輸入正確的Email',
        country_code: '請填寫電話國碼',
        mobile: '請填寫手機號碼',
        payment_id: '請填寫付款方式',
        invoice_type: '請選擇發票開立方式',
        invoice_style: '請選擇發票類型',
        carrier_code: '請填寫手機載具號碼',
        company_no: '請填寫公司統一編號',
        donate_org_code: '請選擇捐贈單位',
        member_id: '發生例外錯誤:缺少會員參數',
        origin_amount: '發生例外錯誤:缺少原始訂單總金額',
        // total_amount: '發生例外錯誤:缺少訂單總金額',
        total_products: '發生例外錯誤:缺少訂單總商品數量',
        total_qty: '發生例外錯誤:缺少訂單總數量',
        expiredDay: '請填寫信用卡有效日期',
      },
    };
  },
  computed: {
    ...mapState([
      'order',
      'orderDetail',
      'takeWay',
      'totalPriceAndCount',
      'abroad_at',
      'backhome_at',
      'acceptRefund',
      'acceptTerms',
      'user',
      'isLogin',
    ]),
    // 取得訂單原始金額
    getOriginAmount() {
      let total = 0;
      if (this.orderDetail) {
        this.orderDetail.forEach((item) => {
          if (item.originTotal) {
            total += item.originTotal;
          } else {
            total += item.total;
          }
        });
      }
      return total;
    },
  },
  methods: {
    // === fix 總計bar用的 ===
    setDOMAttr() {
      this.footerOffsetTop = document.getElementsByTagName('footer')[0].offsetTop;
      this.screenHeight = window.innerHeight;
    },
    setCartButtomBarLink() {
      console.log(this.$route);
      if (this.$route.name === 'CartInfo') {
        this.backRouterPath = '/Product/1';
        this.nextRouterPath = '/Cart/Detail';
        this.backBtnText = '繼續選購';
        this.nextBtnText = '前往結帳';
      } else if (this.$route.name === 'CartDetail') {
        this.backRouterPath = '/Cart/Info';
        this.nextRouterPath = '/Cart/Complete';
        this.backBtnText = '返回購物車';
        this.nextBtnText = '前往結帳';
      }
    },
    changeIfCartButtomBarFix() {
      if (this.screenHeight + this.scrollDistence < this.footerOffsetTop) {
        this.ifCartButtomBarFix = true;
      } else {
        this.ifCartButtomBarFix = false;
      }

      // console.log(
      //   `screenHeight ${this.screenHeight}`,
      //   `scrollDistence ${this.scrollDistence}`,
      //   `footerOffsetTop ${this.footerOffsetTop}`,
      //   this.screenHeight + this.scrollDistence,
      // );
    },
    listenScrollDistence() {
      this.scrollDistence = window.scrollY;
    },
    // ======================

    error(v) {
      this.$customSWAL({
        icon: 'error',
        title: '錯誤',
        text: v,
        confirmButtonText: '確定',
      });
    },

    // 檢查是否有勾選條款
    checkClickTerm() {
      if (this.acceptRefund && this.acceptTerms) {
        return true;
      }
      if (!this.acceptRefund) {
        this.error('請先同意並勾選退貨條款');
      } else if (!this.acceptTerms) {
        this.error('請先同意並勾選租賃條款及隱私權聲明');
      }
      return false;
    },

    // 檢查表單
    checkFormData(formData) {
      let result = true;
      if (formData) {
        Object.keys(formData).every((key) => {
          if (key === 'email') {
            const validateResult = checkMail(formData[key]);
            if (!validateResult) {
              this.error(this.formDataError[key]);
              result = false;
              return false;
            }
          } else if (key === 'company_no') {
            const validateResult = checkCompanyNo(formData[key]);
            if (!validateResult) {
              this.error('請填寫正確的統一編號');
              result = false;
              return false;
            }
          } else if (!required(formData[key]) && this.formDataError[key]) {
            this.error(this.formDataError[key]);
            result = false;
            return false;
          }
          return true;
        });
        if (result) {
          return true;
        }
      }
      return false;
    },

    // 取得總租借天數
    getDays(abroadStart, abroadEnd) {
      if (abroadStart && abroadEnd) {
        const startDay = moment(abroadStart, 'YYYY-MM-DD');
        const endDay = moment(abroadEnd, 'YYYY-MM-DD');
        const days = endDay.diff(startDay, 'days') + 1;
        return days;
      }
      return null;
    },

    /* 更新購物車的折扣後總金額 */
    updateCart(orderDetail) {
      if (orderDetail.length > 0) {
        orderDetail.forEach((item) => {
          const result = this.addCart(item);
          if (!result) {
            return false;
          }
          return true;
        });
        return true;
      }
      return false;
    },

    /* 更新購物車資料(改動為最終折扣後金額,和新增購物車計算不同) */
    addCart(product) {
      const formDataProcess = new Promise((resolve) => {
        const formData = {
          member_id: this.user.id,
        };
        if (product.type === '出租品' || product.category === 'SIM卡' || product.category === 'eSIM') {
          formData.abroad_start = product.abroad_start;
          formData.abroad_end = product.abroad_end;
          formData.days = product.days;
        } else {
          formData.color_id = product.color_id;
        }
        formData.total = product.total;
        formData.product_id = product.product_id;
        formData.price = product.price;
        formData.sell_price = product.sell_price;
        formData.qty = product.qty;
        resolve(formData);
      });
      formDataProcess.then((form) => {
        console.log(form);
        const addCart = requestApi('cart.addCart', form);
        addCart.then((result) => {
          console.log(result);
          if (result.status) {
            // const { cart } = this;
            // this.setCart(cart + 1);
            console.log(result.data);
          } else {
            this.$customSWAL({
              icon: 'error',
              title: '錯誤',
              text: result.message,
              confirmButtonText: '確定',
              thenFunc: () => {
                this.$router.back();
              },
            });
            return false;
          }
          return true;
        });
        return false;
      });
    },

    // 送出表單
    async onSubmit() {
      const {
        // eslint-disable-next-line
        count, total, totalQty, shipping_fee, afterDiscount, discount, discount_code_amount
      } = this.totalPriceAndCount;

      const {
        county,
        district,
        address,
        zipcode,
        range,
        name,
        sex,
        email,
        /* eslint-disable camelcase */
        country_code,
        mobile,
        flight_abroad,
        take_way_id,
        return_way_id,
        take_option_id,
        return_option_id,
        payment_id,
        invoice_type,
        invoice_style,
        carrier_code,
        company_no,
        donate_org_code,
        content,
        ...others
      } = this.order;

      let checkOrderForm;
      // 收件人檢查欄位
      if (this.takeWay === '宅配') {
        checkOrderForm = {
          county,
          district,
          zipcode,
          address,
          name,
          email,
          sex,
          country_code,
          mobile,
        };
      } else {
        checkOrderForm = {
          name,
          sex,
          email,
          country_code,
          mobile,
        };
      }

      // 固定欄位
      const defaultForm = {
        flight_abroad,
        take_way_id,
        return_way_id,
        take_option_id,
        return_option_id,
        payment_id,
        member_id: this.user.id,
        origin_amount: this.getOriginAmount,
        total_amount: total,
        total_products: count,
        shipping_fee,
        discount_event_id: afterDiscount?.id ?? null,
        discount_code: afterDiscount?.code ?? null,
        discount_code_amount: discount_code_amount ?? null,
        discount,
        total_qty: totalQty,
        abroad_at: this.abroad_at,
        backhome_at: this.backhome_at,
        total_day: this.getDays(this.abroad_at, this.backhome_at),
      };

      let invoiceForm;
      // 發票資訊檢查欄位
      // eslint-disable-next-line
      if (invoice_type === 1) {
        // eslint-disable-next-line
        if (invoice_style === 1) {
          // 紙本
          invoiceForm = {
            invoice_type,
            invoice_style,
          };
          // eslint-disable-next-line
        } else if (invoice_style === 2) {
          // 電子
          invoiceForm = {
            invoice_type,
            invoice_style,
            carrier_code,
          };
        } else {
          // 自然人
          invoiceForm = {
            invoice_type,
            invoice_style,
            carrier_code,
          };
        }
      } else if (invoice_type === 2) {
        invoiceForm = {
          invoice_type,
          invoice_style,
          company_no,
        };
      } else if (invoice_type === 3) {
        invoiceForm = {
          invoice_type,
          invoice_style,
          donate_org_code,
        };
      }

      const formData = {
        order: {
          ...checkOrderForm,
          ...defaultForm,
          ...invoiceForm,
          ...others,
        },
        note: {
          content,
        },
      };
      console.log(formData);

      const checkClickTerm = await this.checkClickTerm();
      const checkFormData = await this.checkFormData(checkOrderForm);
      const checkDefaultFormData = await this.checkFormData(defaultForm);
      const checkInvoiceFormData = await this.checkFormData(invoiceForm);
      console.log(checkFormData, checkDefaultFormData, checkInvoiceFormData);

      if (
        checkClickTerm
        && checkFormData
        && checkDefaultFormData
        && checkInvoiceFormData) {
        /* 更新購物車的折扣後總金額 */
        const cartUpdate = await this.updateCart(this.orderDetail);
        if (cartUpdate) {
          const result = await requestApi('cart.store', formData);
          console.log('cart.store', result);
          if (result.status) {
            // linepay
            if (result?.data?.info?.paymentUrl?.web) {
              window.location.href = result.data.info.paymentUrl.web;
            }

            // 信用卡
            if (result?.data?.actionUrl) {
              // window.open('https://wihoapi-dev.howdesign.com.tw/admin/test/testPage?id=6116120c1cc58');
              window.location.href = result.data.actionUrl;
            }

            this.$router.push({
              name: 'CartComplete',
              query: {
                status: true,
                message: '您的訂單已成立，您可隨時到站上查詢訂單資訊',
              },
            });
          } else {
            this.error(`${result.message}`);
          }
        }
      }
    },
  },
  watch: {
    scrollDistence() {
      this.setDOMAttr();
      this.changeIfCartButtomBarFix();
    },
    $route() {
      this.setCartButtomBarLink();
    },
    totalPriceAndCount(data) {
      if (data.count === 0) {
        this.$customSWAL({
          icon: 'error',
          title: '購物車無商品資料',
          text: '',
          confirmButtonText: '確定',
          thenFunc: () => {
            this.$router.push('/');
          },
        });
      }
    },
  },
  created() {
    if (!this.isLogin) {
      this.$customSWAL({
        icon: 'error',
        title: '請先登入會員',
        text: '',
        confirmButtonText: '確定',
        thenFunc: () => {
          this.$router.push('/');
        },
      });
    }
    this.setCartButtomBarLink();
    window.addEventListener('scroll', this.listenScrollDistence, true);
  },
  mounted() {
    this.setDOMAttr();
    this.changeIfCartButtomBarFix();

    // this.$router.push({
    //   name: 'LinepayConfirm',
    //   query: {
    //     transactionId: 123,
    //     orderId: 123,
    //   },
    // });

    // window.location.href = 'https://sandbox-web-pay.line.me/web/payment/waitPreLogin?transactionReserveId=aFZwUHVTalVlc3BZYmpMZGtIdFZBdmlsbFVyekE1RGZJLzVxRGRRMHU2UjB6VDV4UXRubWttUENXUGpGYWpQcA';
  },
  updated() {
    this.setDOMAttr();
  },
  destroyed() {
    window.removeEventListener('scroll', this.listenScrollDistence, true);
  },
};
</script>
