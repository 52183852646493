<template>
  <!-- 前往結帳Bar -->
  <div class="go_checkout_box">
    <div
      ref="goCheckout"
      id="go_checkout"
      class="go_checkout"
      :class="{ fix : ifFix }">
        <div class="go_checkout_inner">
          <div class="go_checkout_total">
            <router-link :to="{ path: prev }">
              <p class="txt-bold">
                <img src="@/assets/img/icon/icon_next-b-rev.svg" alt="" style="margin-right: 10px;">
                {{prevBtnText}}
              </p>
            </router-link>
            <div v-if="totalPriceAndCount.count > 0 && totalPriceAndCount.total > 0">
              <p>{{totalPriceAndCount.count}} 樣商品合計</p>
              <p class="h3 txt-orange txt-highbold">
                <vue-numeric
                  read-only currency="$" separator=","
                  :value="totalPriceAndCount.total">
                </vue-numeric>
              </p>
            </div>
          </div>
          <button
             v-if="(this.$route.name === 'CartDetail') && (nextBtnText === '前往結帳')"
             class="btn btn-orange_arrow"
             @click="$emit('onSubmit')"
            >
            <h6 class="txt-white">
              <span>{{nextBtnText}}</span>
              <img src="@/assets/img/icon/icon_next-w.svg" alt="">
            </h6>
          </button>
          <router-link v-else :to="{ path: next }" class="btn btn-orange_arrow">
            <h6 class="txt-white">
              <span>{{nextBtnText}}</span>
              <img src="@/assets/img/icon/icon_next-w.svg" alt="">
            </h6>
          </router-link>
        </div>
    </div>
    <div
      id="go_checkout_area"
      :class="{ active : ifFix }"
      :style="{ height: `${areaHeight}px` }">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CartButtomBar',
  props: ['ifFix', 'next', 'prev', 'nextBtnText', 'prevBtnText'],
  data() {
    return {
      areaHeight: 0,
    };
  },
  computed: {
    ...mapState([
      'totalPriceAndCount',
    ]),
  },
  methods: {
    initAreaHeight() {
      console.log(this.$refs.goCheckout.offsetHeight);
      this.areaHeight = this.$refs.goCheckout.offsetHeight;
    },
  },
  mounted() {
    this.initAreaHeight();
  },
  updated() {
    this.initAreaHeight();
  },
};
</script>
